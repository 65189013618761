<template>
	<div class="h-full">
		<component class="h-full" :is="layout">
			<header-component />
			<Sidebar v-if="!hideSidebar" />
			<router-view />
		</component>
		<network-error-notification v-if="networkErrorNotification" />
		<validation-error-dialog
			v-if="showValidationDialog"
			:message="validationError.message"
			:description="validationError.description"
			:title="validationError.title"
			@close="hideValidationDialog"
		/>
		<pwa-dialog v-if="showPWADialog" />
		<post-view v-if="showDetailPage" />
		<dialog-component v-if="balanceNotification" @close="closeBalanceNotification">
			<template #header-title>Упс... кончились лайки</template>
			<notification-balance />
		</dialog-component>
		<template v-if="$auth.check('girl')">
			<create-post-dialog @close="closeCreatePostModal" v-if="showCreatePostDialog && !isVideoUploading" />
			<create-post-loader-dialog v-if="showCreatePostDialog && isVideoUploading" />
			<girl-verification-dialog v-if="showVerificationDialog" @close="closeVerificationDialog" />
			<girl-verification-needs-dialog v-if="showVerificationNeedsDialog" @close-dialog="closeVerificationNeedsDialog" />
			<link-greetings-dialog v-if="showLinkGreetingsDialog" @close="closeLinkGreetingsDialog" />
			<girl-verification-status-dialog v-if="showVerificationStatusDialog" @close="closeVerificationStatusDialog" />
		</template>
		<the-app-loader />
		<tutorial-component v-if="showTutorialSlider" :slides="tutorialSlides" @end="onFinishTutorial" />
		<consumers-lists-dialog v-if="showConsumersListsDialog" @close="closeConsumersListsDialog" />
		<consumer-tutorial-component
			v-if="consumerTutorial"
			@close="closeTutorial"
			@shown="shownTutorial"
			@end="closeTutorial((isEnded = true))"
		/>
		<sub-limit-dialog v-if="showSubLimitModal" @decline="closeSubLimitModal" @close="closeSubLimitModal" />
	</div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useModalStore } from '@/stores/modal'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { useCreatePostStore } from '@/stores/createPost'
import { useCommonStore } from '@/stores/common'
import { useTutorialStore } from '@/stores/tutorial'
import { useConsumerTutorialStore } from '@/stores/consumerTutorial'
import { useNotificationsStore } from '@/stores/notifications'
import { useCurrentUserStore } from '@/stores/user'
import { usePostsStore } from '@/stores/posts'
import { detectIOS, detectSafari } from '@/helpers/detectPlatform'
import NetworkErrorNotification from '@/components/NetworkErrorNotification.vue'
import CreatePostDialog from '@/components/dialogs/CreatePostDialog.vue'
import NotificationBalance from '@/components/NotificationBalance.vue'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import pwaDialog from '@/components/dialogs/pwaDialog.vue'
import CreatePostLoaderDialog from '@/components/dialogs/CreatePostLoaderDialog.vue'
import GirlVerificationDialog from '@/components/dialogs/GirlVerificationDialog.vue'
import ValidationErrorDialog from '@/components/dialogs/ValidationErrorDialog.vue'
import TheAppLoader from '@/components/TheAppLoader.vue'
import TutorialComponent from '@/components/TutorialComponent.vue'
import GirlVerificationNeedsDialog from '@/components/dialogs/GirlVerificationNeedsDialog.vue'
import ConsumerTutorialComponent from '@/components/ConsumerTutorialComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import LinkGreetingsDialog from '@/components/dialogs/LinkGreetingsDialog.vue'
import GirlVerificationStatusDialog from '@/components/dialogs/GirlVerificationStatusDialog.vue'
import PostView from '@/views/PostView.vue'
import Sidebar from '@/components/desktop/Sidebar.vue'
import HeaderComponent from '@/components/HeaderComponent.vue'
import HeaderLogo from '@/components/HeaderLogo.vue'
import ConsumersListsDialog from '@/components/dialogs/ConsumersListsDialog.vue'
import SubLimitDialog from '@/components/dialogs/SubLimitDialog.vue'

export default {
	components: {
		ConsumersListsDialog,
		GirlVerificationNeedsDialog,
		TutorialComponent,
		TheAppLoader,
		GirlVerificationDialog,
		CreatePostLoaderDialog,
		DialogComponent,
		pwaDialog,
		CreatePostDialog,
		NetworkErrorNotification,
		NotificationBalance,
		ButtonComponent,
		ConsumerTutorialComponent,
		LinkGreetingsDialog,
		GirlVerificationStatusDialog,
		PostView,
		Sidebar,
		HeaderComponent,
		HeaderLogo,
		ValidationErrorDialog,
		SubLimitDialog
	},
	computed: {
		...mapState(useTutorialStore, ['tutorialSlides', 'showTutorialSlider']),
		...mapState(useConsumerTutorialStore, ['consumerTutorial']),
		...mapState(useVideoUploadStore, ['isVideoUploading']),
		...mapState(useCreatePostStore, ['showValidationDialog', 'validationError', 'scrollY']),
		...mapState(useModalStore, [
			'showConsumersListsDialog',
			'networkErrorNotification',
			'showVerificationDialog',
			'balanceNotification',
			'showVerificationNeedsDialog',
			'showPWADialog',
			'showLinkGreetingsDialog',
			'showVerificationStatusDialog',
			'showDetailPage',
			'showSubLimitModal'
		]),
		...mapState(useCreatePostStore, ['showCreatePostDialog']),
		...mapState(useCurrentUserStore, {
			isAuthorised: 'isAuthorised'
		}),
		...mapWritableState(useCommonStore, ['isPowerSaveMode']),
		layout() {
			return this.$route.meta.layout || 'default'
		},
		hideSidebar() {
			return this.$route.meta.hideSidebar
		}
	},
	methods: {
		...mapActions(useTutorialStore, ['startTutorial', 'finishTutorial']),
		...mapActions(useConsumerTutorialStore, ['setTutorial', 'finishTutorial']),
		...mapActions(useCreatePostStore, ['closeCreatePostDialog', 'hideValidationDialog', 'setScrollY']),
		...mapActions(useCommonStore, ['setDeferredPrompt']),
		...mapActions(useModalStore, {
			openVerificationDialog: 'openVerificationDialog',
			closeVerificationDialog: 'closeVerificationDialog',
			closeBalanceNotification: 'closeBalanceNotification',
			closeVerificationNeedsDialog: 'closeVerificationNeedsDialog',
			closeLinkGreetingsDialog: 'closeLinkGreetingsDialog',
			closeVerificationStatusDialog: 'closeVerificationStatusDialog',
			closeConsumersListsDialog: 'closeConsumersListsDialog',
			openSubLimitModal: 'openSubLimitModal',
			closeSubLimitModal: 'closeSubLimitModal'
		}),
		...mapActions(useNotificationsStore, {
			setLoadedState: 'setLoadedState',
			fetchChatPushState: 'fetchChatPushState',
			fetchPushSubscriptionStatus: 'fetchPushSubscriptionStatus'
		}),
		...mapActions(usePostsStore, {
			sendViewedPost: 'sendViewedPost'
		}),
		async onFinishTutorial() {
			const finishedTutorialName = await this.finishTutorial('latest-slider')

			if (finishedTutorialName === 'secondStep') {
				await this.startTutorial('thirdStep')
			}
		},
		closeTutorial(slideIndex, isEnded) {
			this.setTutorial(null)
			if (isEnded) this.$metrika.reachGoal('onboarding_button_start')
			else this.$metrika.reachGoal(`onbording-close${slideIndex}`)
		},
		shownTutorial() {
			this.finishTutorial('onboarding')
		},
		captureEvent() {
			if (localStorage.getItem('pwaAsk') === null) {
				window.addEventListener('beforeinstallprompt', (e) => {
					e.preventDefault()
					this.setDeferredPrompt(e)
				})
			}
		},
		closeCreatePostModal() {
			this.closeCreatePostDialog()
			if (this.scrollY) {
				this.$nextTick(() => {
					window.scrollTo(0, this.scrollY)
					this.setScrollY(null)
				})
			}
		}
	},
	watch: {
		isAuthorised: {
			async handler(val) {
				if (val) {
					await this.fetchChatPushState()
				}
			},
			immediate: true
		}
	},
	async beforeMount() {
		if ('serviceWorker' in navigator) {
			await navigator.serviceWorker
				.register('/service-worker.js', { scope: '/', type: 'module' })
				.then(() => {
					document.addEventListener('visibilitychange', async () => {
						navigator.serviceWorker.ready.then((sw) => {
							sw.active.postMessage({ isPageVisible: !document.hidden })
						})
					})
				})
				.catch((e) => {
					throw new Error(e.toString())
				})
		} else {
			throw new Error('Service workers are not supported.')
		}
	},
	mounted() {
		if (detectSafari()) {
			const r = document.querySelector('body')
			r.style.fontFamily = `'Inter', JoyPixelsSBIX, JoyPixelsSVG, sans-serif`
		}
		const video = document.createElement('video')
		video.src = '#'
		video.controls = false
		video.muted = true
		video.playsInline = true
		document.body.appendChild(video)
		video
			.play()
			.then(() => {})
			.catch((e) => {
				if (detectIOS() && e.toString().includes('NotAllowedError')) {
					this.isPowerSaveMode = true
				}
			})
			.finally(() => {
				video.remove()
			})
		this.captureEvent()
		window.addEventListener('blur', this.sendViewedPost)
	}
}
</script>
