<template>
	<span class="custom-checkbox">
		<input
			hidden
			type="checkbox"
			v-bind="$attrs"
			:value="value"
			v-model="values"
			@input="$emit('input', $event.target.value)"
		/>
	</span>
</template>

<script>
export default {
	name: 'CustomCheckbox',
	model: {
		prop: 'checked',
		event: 'change'
	},
	props: {
		checked: {
			type: null,
			required: false,
			default: false
		},
		value: {
			type: null,
			required: false,
			default: false
		}
	},
	computed: {
		values: {
			get() {
				return this.checked
			},
			set(value) {
				this.$emit('change', value)
			}
		}
	}
}
</script>

<style scoped lang="scss">
.custom-checkbox {
	width: 20px;
	height: 20px;
	min-width: 20px;
	background: transparent;
	border-radius: 50%;
	border: 1.5px solid #bebec9;
	position: relative;

	&:has(input:checked)::after,
	&.inverse::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 12px;
		height: 12px;
		background: #bebec9;
		transform: translate(-50%, -50%);
		border-radius: inherit;
	}

	&.inverse:has(input:checked)::after {
		display: none;
	}
}
</style>
